
<div class="container" *ngIf="pucg == 0">
  <!-- <div class="sec-title text-center">
      <span class="title">Get plan</span>
      <h2>Choose a Plan</h2>
  </div> -->

  <div class="outer-box" *ngIf="step == 1 && his != 'history'">


    <div *ngIf="promo == 1" class="col-md-12 " [class.order-first]="promo == 1">
      <div class="my-5 py-4 px-5 bg-light d-sm-flex align-items-center justify-content-between border border-success"
        (click)="options = 'PROMO'; pucg = 1">
        <div class="text-center">
          <span style="font-weight: 600">
            <div class="icon-outer"><i class="fa fa-product-hunt " style="font-size: 70px; color: burlywood;"></i></div>PROMO
          </span>
          <h4 class="my-2 font-weight-bold">$1,000 - 2,999</h4>
          <small class="text-uppercase text-secondary">4 days</small>
        </div>
        <div class="text-center text-uppercase text-secondary mb-2">
          <small class="d-block my-3" style="font-weight: 600">400% interest in 4 days</small>
          <!--  <small class="d-block my-3" style="font-weight: 600;">10GB Bandwidth</small> -->
          <small class="d-block" style="font-weight: 600">24/7 Support</small>
        </div>
      </div>

      <div class="my-5 py-4 px-5 bg-light d-sm-flex align-items-center justify-content-between border border-success"
        (click)="options = 'SPECIAL PROMO'; pucg = 1">
        <div class="text-center">
          <span style="font-weight: 600">
            <div class="icon-outer"><i class="fa fa-product-hunt " style="font-size: 70px; color: teal;"></i></div>SPECIAL PROMO</span>
          <h4 class="my-2 font-weight-bold">$3,000 - 9,999</h4>
          <small class="text-uppercase text-secondary">4 days</small>
        </div>
        <div class="text-center text-uppercase text-secondary mb-2">
          <small class="d-block my-3" style="font-weight: 600">4 days 400% + 5000</small>
          <!--  <small class="d-block my-3" style="font-weight: 600;">10GB Bandwidth</small> -->
          <small class="d-block" style="font-weight: 600">24/7 Support</small>
        </div>
      </div>

      <div class="my-5 py-4 px-5 bg-light d-sm-flex align-items-center justify-content-between border border-success"
        (click)="options = 'PROMO PRO'; pucg = 1">
        <div class="text-center">
          <span style="font-weight: 600">
            <div class="icon-outer"><i class="fa fa-product-hunt " style="font-size: 70px; color: gold;"></i></div>PROMO GOLD</span>
          <h4 class="my-2 font-weight-bold">Above $10,000</h4>
          <small class="text-uppercase text-secondary">4 days</small>
        </div>
        <div class="text-center text-uppercase text-secondary mb-2">
          <small class="d-block my-3" style="font-weight: 600">400% (+ 15000) 4 days</small>
          <!--  <small class="d-block my-3" style="font-weight: 600;">10GB Bandwidth</small> -->
          <small class="d-block" style="font-weight: 600">24/7 Support</small>
        </div>
      </div>
    </div>


      <div class="row">
          <!-- Pricing Block -->
          <div class="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div class="inner-box">
                  <div class="icon-box">
                      <div class="icon-outer"><i class="fa fa-paper-plane"></i></div>
                  </div>
                  <div class="price-box">
                      <div class="title"> Emerald</div>
                      <h4 class="price">$25 -  $249</h4>
                  </div>
                  <ul class="features">
                      <li class="true">1 day</li>
                      <li class="true">+12% Growth</li>
                      <li class="true" style="font-weight: bold; font-size: 15px;"><strong>{{  emrd }} Investor(s)</strong></li>
                  </ul>
                  <div class="btn-box" (click)="options = 'EMRD'; pucg = 1">
                      <a href="javascript:void(0);" class="theme-btn">BUY plan</a>
                  </div>
              </div>
          </div>




          <div class="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
            <div class="inner-box">
                <div class="icon-box">
                    <div class="icon-outer"><i class="fa fa-paper-plane"></i></div>
                </div>
                <div class="price-box">
                    <div class="title"> Ruby</div>
                    <h4 class="price">$250 -  $999</h4>
                </div>
                <ul class="features">
                    <li class="true">3 days</li>
                    <li class="true">+30% Growth</li>
                    <li class="true" style="font-weight: bold; font-size: 15px;"><strong>{{  ruby }} Investor(s)</strong></li>
                </ul>
                <div class="btn-box" (click)="options = 'RUBY'; pucg = 1">
                    <a href="javascript:void(0);" class="theme-btn">BUY plan</a>
                </div>
            </div>
         </div>

          <!-- Pricing Block -->
          <div class="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="400ms">
              <div class="inner-box">
                  <div class="icon-box">
                      <div class="icon-outer"><i class="fa fa-diamond"></i></div>
                  </div>
                  <div class="price-box">
                      <div class="title">Diamond</div>
                      <h4 class="price">$1000.99 -  9999</h4>
                  </div>
                  <ul class="features">
                      <li class="true">5 days</li>
                      <li class="true">+100% Growth</li>
                      <li class="true" style="font-weight: bold; font-size: 15px;"><strong>{{  dmd }} Investor(s)</strong></li>
                  </ul>
                  <div class="btn-box" (click)="options = 'DMD'; pucg = 1">
                      <a href="javascript:void(0);" class="theme-btn">BUY plan</a>
                  </div>
              </div>
          </div>

          <!-- Pricing Block -->
          <div class="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="800ms">
              <div class="inner-box">
                  <div class="icon-box">
                      <div class="icon-outer"><i class="fa fa-rocket"></i></div>
                  </div>
                  <div class="price-box">
                      <div class="title">Rocket</div>
                      <h4 class="price">$10000 - unlimited</h4>
                  </div>
                  <ul class="features">
                      <li class="true">10 days</li>
                      <li class="true">200% Growth</li>
                      <li class="true" style="font-weight: bold; font-size: 15px;"><strong>{{  roct }} Investor(s)</strong></li>
                  </ul>
                  <div class="btn-box" (click)="options = 'ROCT'; pucg = 1">
                      <a href="javascript:void(0);" class="theme-btn">BUY plan</a>
                  </div>
              </div>
          </div>
      </div>


      <div class="my-5 py-3 px-3 bg-light">
        <div class=" d-sm-flex align-items-center justify-content-between" (click)="options = 'CIPs'; pucg = 1"
          style="cursor: pointer">
          <div class="d-md-flex justify-content-between align-items-center">
            <div class="text-center">
              <p><span class="text-primary">
                  <img src="https://www.uscis.gov/sites/default/files/images/site/DHS_cis_W.svg" loading="lazy"
                    width="170px" height="100px" alt="" class="sticky-card_icon" />
                </span> </p>
              <h4 class="mb-0 fw-200 text-center">Citizenship by Investment (CIPs)</h4>
            </div>
          </div>
          <div class="text-center text-uppercase text-secondary mb-2">
            <small class="d-block my-3" style="font-weight: 600">Duration 1 month</small>
             <small class="d-block my-3" style="font-weight: 600;">{{  cip }} Investor(s)</small>
            <small class="d-block" style="font-weight: 600">
              <div class="mt-10 mt-md-0">
                <a href="javascript:void(0);" class="waves-effect waves-light btn btn-outline btn-primary">Invest
                  Now</a>
              </div>
            </small>
          </div>
        </div>
        <hr>
        <div class="d-md-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-start align-items-center">
            <div class="min-w-100">
              <p class="mb-0 text-fade">Fund Size</p>
              <h6 class="mb-0">$10,000 - 50,000</h6>
            </div>
            <div class="mx-lg-50 mx-20 min-w-70">
              <p class="mb-0 text-fade">Return</p>
              <h6 class="mb-0 text-success">+500% <span class="text-dark">(1 month)</span></h6>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>




<div *ngIf="pucg == 1">
  <div class="" *ngIf="bank == '0'">
    <div class="container">
      <div class="card">
        <div class="card-body">
          <div class="buy-sell-widget">
            <ul class="nav nav-tabs">
              <li class="nav-item w-100">
                <a
                  class="nav-link active"
                  data-toggle="tab"
                  href="#buy"
                  style="background-color: teal !important; border-radius: 30px;"
                  >Deposit Invest</a
                >
              </li>
            </ul>

            <form
              (ngSubmit)="calculate(so)"
              #so="ngForm"
              *ngIf="bank == '0'"
              class="currency_validate"
              novalidate="novalidate"
            >
              <div class="form-group" *ngIf="money == 1">
                <label class="mr-sm-2">Currency</label>

                <div class="row">
                  <div class="col-md-3 col-6" *ngIf="country == 'India'">
                    <input class="checkbox-tools" type="radio" name="spend" ngModel required id="tool-0" value="Bank" />
                    <label class="for-checkbox-tools" for="tool-0">
                      <div class="serv_icon">
                        <img src="https://cdn.iconscout.com/icon/free/png-256/bank-transfer-1817143-1538011.png"
                          style="width: 100%; height: 100%" alt="" />
                      </div>
                      <div class="service-content">
                        <h6 class="d-blue bold">BANK</h6>
                      </div>
                    </label>
                  </div>


                  <div class="col-md-3 col-6" *ngIf="country == 'India'">
                    <input class="checkbox-tools" type="radio" name="spend" ngModel required id="tool-20" value="UPI" />
                    <label class="for-checkbox-tools" for="tool-20">
                      <div class="serv_icon">
                        <img src="https://cdn.iconscout.com/icon/free/png-256/bank-transfer-1817143-1538011.png"
                          style="width: 100%; height: 100%" alt="" />
                      </div>
                      <div class="service-content">
                        <h6 class="d-blue bold">UPI</h6>
                      </div>
                    </label>
                  </div>


                  <div class="col-md-3 col-6">
                    <input
                      class="checkbox-tools"
                      type="radio"
                      ngModel
                      required
                      name="spend"
                      id="tool-1"
                      value="BTC"
                    />
                    <label class="for-checkbox-tools" for="tool-1">
                      <div class="serv_icon">
                        <img
                          src="https://firstradeonline.com/assets/icon/btc.svg"
                          style="width: 100%; height: 100%"
                          alt=""
                        />
                      </div>
                      <div class="service-content">
                        <h6 class="d-blue bold">BITCOIN</h6>
                      </div>
                    </label>
                  </div>

                  <div class="col-md-3 col-6">
                    <input
                      class="checkbox-tools"
                      type="radio"
                      name="spend"
                      value="USDT(ERC20)"
                      id="tool-4"
                      ngModel
                      required
                    />
                    <label class="for-checkbox-tools" for="tool-4">
                      <div class="serv_icon">
                        <img
                          src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/256/Tether-USDT-icon.png"
                          style="width: 100%; height: 100%"
                          alt=""
                        />
                      </div>
                      <div class="service-content">
                        <h6 class="d-blue bold" style="font-size: 11px">
                          USDT(ERC20)
                        </h6>
                      </div>
                    </label>
                  </div>


                  <div class="col-md-2 col-6">
                    <input class="checkbox-tools" type="radio" name="spend" value="USDT(TRC20)" id="tool-5" ngModel
                      required />
                    <label class="for-checkbox-tools" for="tool-5">
                      <div class="serv_icon">
                        <img
                          src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/256/Tether-USDT-icon.png"
                          style="width: 100%; height: 100%" alt="" />
                      </div>
                      <div class="service-content">
                        <h6 class="d-blue bold" style="font-size: 11px;">USDT(TRC20)</h6>
                      </div>
                    </label>
                  </div>



                  <div class="col-md-2 col-6">
                    <input class="checkbox-tools" type="radio" name="spend" value="USDT(BEP20)" id="tool-55" ngModel
                      required />
                    <label class="for-checkbox-tools" for="tool-55">
                      <div class="serv_icon">
                        <img
                          src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/256/Tether-USDT-icon.png"
                          style="width: 100%; height: 100%" alt="" />
                      </div>
                      <div class="service-content">
                        <h6 class="d-blue bold" style="font-size: 11px;">USDT(BEP20)</h6>
                      </div>
                    </label>
                  </div>

                  <div class="col-md-3 col-6">
                    <input
                      class="checkbox-tools"
                      type="radio"
                      name="spend"
                      ngModel
                      required
                      id="tool-7"
                      value="Account"
                    />
                    <label class="for-checkbox-tools" for="tool-7">
                      <div class="serv_icon">
                        <img
                          src="https://img.freepik.com/free-vector/brown-leather-wallet-with-lots-money_68708-304.jpg?size=626&ext=jpg"
                          style="width: 100%; height: 100%"
                          alt=""
                        />
                      </div>
                      <div class="service-content">
                        <h6 class="d-blue bold" style="font-size: 11px">
                          Balance
                        </h6>
                      </div>
                    </label>
                  </div>



                  <button
                    type="submit"
                    class="btn btn-primary btn-round btn-block"
                    [disabled]="!so.valid"
                  >
                    Proceed
                  </button>
                </div>
              </div>

              <div
                class="form-group"
                [ngClass]="money >= 1 ? 'd-none' : 'd-block'"
              >
                <label class="mr-sm-2">Enter your amount</label>
                <div class="input-group">
                  <input
                    type="number"
                    ngModel
                    name="deposit"
                    required
                    (change)="atLeastOneRadio()"
                    class="form-control form-control-amount form-control-lg blue-light-background"
                    id="custom-amount"
                    value="0"
                    placeholder="30.00"
                  />
                </div>
              </div>

              <div class="form-group">
                <div
                  class="d-flex justify-content-between mt-3"
                  *ngIf="display == 1"
                >
                  <p class="mb-0">Invest: {{ ammethod | currency }}</p>
                  <h6 class="mb-0">Earn: {{ profit | currency }}</h6>
                </div>
              </div>

              <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="money == 0">
                <button
                  type="button"
                  class="btn btn-success btn-round btn-block"
                  *ngIf="display == 0"
                  (click)="money = 1"
                  [disabled]="!chek()"
                >
                  Pay Now
                </button>
              </div>
              <button
                type="button"
                name="submit"
                class="btn btn-success btn-block"
                *ngIf="display == 1"
                (click)="Deposit()"
              >
                Invest Now
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="bank == '2'">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12">
          <div class="row justify-content-center">
            <div class="col-md-12 mb-3">
              <div class="card shadow-lg">
                <div class="rounded-bottom card-body text-white">
                  <p class="opacity-75 text-center">
                    Pay ${{ amountTopay }} Send {{ tic }}to address
                  </p>
                  <input
                    type="text"
                    id="address"
                    class="form-control form-control-lg mt-2 mb-2"
                    [value]="addressTopay"
                    id="refUrl"
                    readonly=""
                  />
                  <button
                    class="btn btn-primary btn-block btn-lg my-link-copy mb-2"
                    data-clipboard-target="#my-link-m"
                    (click)="copy()"
                  >
                    COPY
                  </button>
                  <div class="rounded p-3 bg-white-translucent">
                    <p class="font-size-sm mb-0" style="color: black">
                      <span class="text-primary">ATTENTION</span> - Please send
                      {{ amountTopay }} dollar worth of {{ tic }}
                      with gas fee depending on your personal wallet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="bank == '1' && country == 'India'">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12">
          <div *ngFor="let item of banks">
            <div class="row justify-content-center">
              <div class="col-md-12 mb-3">
                <div class="card shadow-lg">
                  <div class="rounded-bottom card-body text-white">
                    <label for="">Bank name</label>
                    <input
                      type="text"
                      id="address"
                      class="form-control form-control-lg mt-2 mb-2"
                      [value]="item.Bank"
                      readonly=""
                    />
                    <label for="">Account number</label>
                    <input
                      type="text"
                      id="address"
                      class="form-control form-control-lg mt-2 mb-2"
                      [value]="item.Account"
                      readonly=""
                    />
                    <label for="">Account name</label>
                    <input
                      type="text"
                      id="address"
                      class="form-control form-control-lg mt-2 mb-2"
                      [value]="item.Accountname"
                      readonly=""
                    />
                    <label for="">IFSC CODE</label>
                    <input
                      type="text"
                      id="address"
                      class="form-control form-control-lg mt-2 mb-2"
                      [value]="item.branch"
                      readonly=""
                    />
                    <label for="">Amount</label>
                    <input
                      type="text"
                      id="address"
                      class="form-control form-control-lg mt-2 mb-2"
                      [value]="amm"
                      readonly=""
                    />

                    <div class="rounded p-3 bg-white-translucent">
                      <!--    <p class="font-size-sm mb-0" style="background-color: black;">
                        <span class="text-primary">ATTENTION</span> -After Payment, contact the adminstrator with your
                        proof
                        of payment or<b><a style="text-decoration: underline;"
                            href="javascript:void(Tawk_API.toggle())">Live
                            Chat</a></b> for
                        quick response
                      </p> -->

                      <p style="background-color: black">
                        * For easy confirmation make payments with this
                        paymentID
                        <b
                          ><em style="color: white">{{ rand }}</em></b
                        >
                        on your bank teller
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
